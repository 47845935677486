import axios from 'axios';
import { useState } from 'react';
import SearchBar from './SearchBar'; // Assuming you have a SearchBar component

const GenerateRecipes = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [recipe, setRecipe] = useState(null);

    const generateRecipe = async (query) => {
        console.log("Generating recipe for:", query);
        setLoading(true);
        setError(null);
        setRecipe(null);

        try {
            const response = await axios.post('http://asteroidbelter.com/api/generate_recipe:5000', {
                ingredients: query,
            });

            console.log('Recipe Generated:', response.data);
            setRecipe(response.data);
        } catch (error) {
            console.error('Error:', error);
            setError(error.response?.data?.message || error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Recipe Generator</h1>
            <SearchBar generateRecipe={generateRecipe} />
            {loading && <p>Generating recipe...</p>}
            {error && <p style={{ color: 'red' }}>Error: {error}</p>}
            {recipe && (
                <div>
                    <h2>Your Recipe:</h2>
                    <pre>{JSON.stringify(recipe, null, 2)}</pre>
                </div>
            )}
        </div>
    );
};

export default GenerateRecipes;

